.outerAreaContainer {
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;
  background-color: #f2f2f2;
  height:100%;
  width:100%;
  pointer-events: auto;
  user-select: auto;
}

.outerAreaContainer[data-custom-styling=true] {
  border: var(--area-border-style) !important;
  border-radius: var(--area-border-radius) !important;
}

.outerAreaContainer[data-final-top-left-border-radius=true] {
  border-top-left-radius: var(--final-top-left-border-radius) !important;
}

.outerAreaContainer[data-final-top-right-border-radius=true] {
  border-top-right-radius: var(--final-top-right-border-radius) !important;
}

.outerAreaContainer[data-final-bottom-left-border-radius=true] {
  border-bottom-left-radius: var(--final-bottom-left-border-radius) !important;
}

.outerAreaContainer[data-final-bottom-right-border-radius=true] {
  border-bottom-right-radius: var(--final-bottom-right-border-radius) !important;
}

.outerAreaContainerDisplayFlex {
  display: flex;
}

.SplitLayout {
  border: 1px solid #008fff;
  border-radius: 3px;
}

/* .SplitLayout:hover > div > .outerAreaContainer{
  border: 2px solid #007bff;
} */

.viewMode {
  border: none;
}

.hoverToEdit {
  position: relative;
  display: inline-block;
}

.hoverIndicator {
  padding: 1px;
  height: 6px;
  background-color: #00589b;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 100%;
  right: 0;
}

.hoverToEdit .hoverIndicator:hover {
  background-color: #777777;
}

.editArea {
  display: none;
}

.hoverToEdit:hover .editArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(199, 199, 199);
  background-color: #777777;
  padding: 6px 8px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.editButtonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menubar {
  padding: 6px 8px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  background-image22: linear-gradient(#f1f1f1, #b6b6b6);
  background-color2: #e9e9e9;
  background-color: rgb(224, 221, 221);

  white-space: nowrap;
}
.marginTop15 {
  margin-top: 15px;
}
.marginLeft5 {
  margin-left: 5px;
}
.marginLeft10 {
  margin-left: 10px;
}

.menubartxt {
  padding-left: 5px;
  text-align: center;
  color: #000;
  width: 100%;
}

.viewMode > .editArea {
  display: none;
}

.editIcon {
  width: 20px;
  cursor: pointer;
}

.insertIcon {
  width: 13px;
  margin: 0 4px;
  cursor: pointer;
}

.w2ui {
  font-family: Verdana, Arial, sans-serif;
  font-size: 13px;
  align-content: center;
}

.text11 {
  font-family: Verdana, Arial, sans-serif;
  font-size: 11px;
}

.border {
  border: 1px solid;
  border-color: #898a8a;
  padding: 0px;
  box-shadoweee: 15px 10px #d60909;
}
.border2 {
  border: 2px solid;
  padding: 0px;
  box-shadoweee: 15px 10px #d60909;
}

.noscrollbar {
  overflow: hidden;
}

.padding1 {
  border: none;
  padding: 5px;
}
.padding2 {
  border: none;
  padding: 10px;
}
.padding3 {
  border: none;
  padding: 15px;
}

.none {
  border: none;
  padding: 0px;
  margin: 0px;
}
.noneWhite {
  border: none;
  padding: 0px;
  margin: 0px;
}
.noneWhite :global(.inheritFromArea) {
  background-color: #ffffff;
}

.refresh,
.threeColorTemplate {
  border-radius: 12px;
  padding: 0;
  background-color: transparent;
}

.threeColorTemplate.zeroBorderRadiusWidget {
  border-radius: 0;
}

.areaMenuBarGrey {
  background-image: linear-gradient(#dae6f3, #c2d5ed);
}

.paper {
  position: relative;
  margin: 5px;
  width: 95%;
  height: 98%;
  background-color: #ffffff;

  border-radius: 0px;

  box-shadow: 10px 5px 5px lightgrey;
}
.paper2Org {
  position2: relative;
  margin-top: 15px;
  margin-right: 10px;
  margin-bottom: 7px;
  margin-left: 7px;
  width:calc(100% - 22px) ;
  height: calc(100% - 22px) ;
  background-color: #e9e9e9;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 7px lightgrey;
}
.noScrollBars
{
 scrollbar:hidden ;
}

.paper2 {
  position2: relative;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 1px;
  margin-left: 5px;
  width:calc(100% - 15px) ;
  height: calc(100% - 12px) ;
  background-color: #e9e9e9;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 3px lightgrey;
}
.toolbar {
  margin-top: 4px;
  margin-right: 10px;
  margin-bottom: 4px;
  margin-left: 0px;
  width: 100%;
  height: 90%;
  background-color: #949393;
  border-radius: 1px;
  box-shadow2: 0px 0px 7px 7px rgb(180, 178, 178);
}
.noScrollBars
{
  overflow: hidden;
}
