:root {
    --primary-color: #1c1259;
    --sub-menu-color: #110b37;
    --active-color: #ada3fa;

    --text-color: #e3e3e3;
    --text-hover-color: white;

    --outer-padding: 0.3rem;
    --outer-padding-small: 0.2rem;

    --top-bar-height: 45px;
    --top-bar-height-small: 31px;

    --item-spacing: .25rem;
    --item-spacing-small: .17rem;

    --item-padding: 0.4rem 0.75rem;
    --item-padding-small: 0.20rem 0.5rem;

    --font-size: 15px;
    --font-size-small: 12px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    gap: var(--item-spacing);

    min-height: var(--top-bar-height);
    padding: var(--outer-padding) 0.5rem;
    margin: 0;

    list-style: none;
    background: var(--primary-color);
}

.top-bar.small {
    min-height: var(--top-bar-height-small);
    gap: var(--item-spacing-small);
    padding: var(--outer-padding-small) 0.5rem;
}

.item {
    display: inline-flex;
    align-content: center;
    padding: var(--item-padding);
    position: relative;

    background: transparent;
    border: none;
    border-radius: 4px;

    font-family: 'Exo 2 Variable', sans-serif;
    font-size: var(--font-size);

    user-select: none;
    white-space: nowrap;
}

.top-bar.small .item {
    font-size: var(--font-size-small);
    padding: var(--item-padding-small);
}

/* fix icon text alignment */
.top-bar.small .item > a > span {
    margin-top: -1px;
}

.item.clickable {
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.item.clickable:hover {
    color: var(--text-hover-color);
    background: rgba(255, 255, 255, 0.15);
}

.item.clickable:active {
    background: rgba(255, 255, 255, 0.2);
}

.item:not(.clickable) > a {
    cursor: default;
}

.item.active {
    background: var(--active-color);
    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.15);
}
.item.active:hover {
    background: var(--active-color);
}

.item, .item a {
    color: var(--text-color);
}

.item.active, .item.active a {
    color: var(--primary-color);
}

.item a {
    text-decoration: none;

    display: inline-flex;
    align-items: center;
    gap: .5rem;
}

.sub-menu {
    display: none;
    position: absolute;
    top: calc(var(--top-bar-height) - (var(--outer-padding) * 2));
    right: 0;
    z-index: 100;
    min-width: 100%;

    margin: 0;
    padding: 0.3rem;
    gap: var(--item-spacing);

    list-style: none;
    background: var(--sub-menu-color);
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.top-bar.small .sub-menu {
    top: calc(var(--top-bar-height-small) - (var(--outer-padding-small) * 2));
}

.item:hover .sub-menu {
    display: flex;
    flex-direction: column;
}

.separator {
    flex-grow: 1
}

.chevron {
    opacity: 0.75;
    font-size: 75%;
}

.item > a > .icon-text {
    display: inline-flex;
}

@media (max-width: 480px) {
    .top-bar > .item > a > .icon-text {
        display: none
    }
}
